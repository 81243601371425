import { toast } from 'sonner';

export const notifySuccess = (message: string) => {
  toast.success(message, {
    position: 'top-right',
    duration: 5000,
  });
};

export const notifyError = (error: string) => {
  toast.error(error, {
    position: 'top-right',
    duration: 5000,
  });
};
