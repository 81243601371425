import { LANGUAGE_LIST } from 'kikifarmer/locales/constants/languages';
import { useTranslation } from 'react-i18next';
import { Lang } from 'kikifarmer/locales/constants/types';

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGUAGE_LIST.find((_lang) => _lang.value === langStorage) || LANGUAGE_LIST[1];

  const handleChangeLanguage = async (newLang: string, isSaveToCloud = true) => {
    // @ts-ignore
    await i18n.changeLanguage(newLang);
    if (isSaveToCloud) {
      // await userServices.updateUserLanguage({ language: newLang });
    }
  };

  const getI18nText = (
    text?: Record<Lang, string>,
    { fallbackLang }: { fallbackLang: Lang } | undefined = { fallbackLang: 'vi' },
  ) => {
    if (!text) return '';
    return text[currentLang.value] ?? text[fallbackLang] ?? '--';
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGUAGE_LIST,
    getI18nText,
  };
}
