import React, { useEffect, useState } from 'react';
import SocketFactory from 'kikifarmer/core/socket/socket-service.js';
import { AppState, channel, keyCodeMapped, target } from 'kikifarmer/example.js';
import TransactionService from 'kikifarmer/core/transaction/transaction.service.js';
import TransactionError from 'kikifarmer/core/transaction/transaction.error.js';
import ControlServiceFactory from 'kikifarmer/core/control/control.service.js';
import DeviceScreen from 'kikifarmer/core/screen/DeviceScreen.jsx';
import ScalingService from 'kikifarmer/core/screen/scaling/scaling.service.js';
import VendorUtil from 'kikifarmer/core/util/vendor/vendor.util.js';
import PageVisibilityService from 'kikifarmer/core/page-visibility/page-visibility.service.js';
import DeviceActions from 'kikifarmer/features/device/ui/DeviceActions.jsx';
import { Box } from '@mui/material';
import UsbOffOutlinedIcon from '@mui/icons-material/UsbOffOutlined';

function DeviceWrapper({ device, onlyView = false, viewInPIP = false, zoomOutRatio = 1, showActions = true, refetch }) {
  const [socket, setSocket] = useState();

  useEffect(() => {
    const _socket = onlyView || viewInPIP ? null : SocketFactory(AppState);
    setSocket(_socket);

    if (_socket) {
      _socket.on('device.add', () => {
        console.log('new device connect');
      });
      _socket.on('device.remove', () => {
        console.log('new device remove');
      });
      _socket.on('device.change', (e) => {
        console.log('change', e);
      });
    }
  }, []);

  const reconnect = async () => {
    var tx = scope.transactionService.create(device);
    socket.emit('group.invite', device.channel, tx.channel, {
      requirements: {
        serial: {
          value: device.serial,
          match: 'exact',
        },
      },
    });
    await refetch?.();
  };

  if (!socket && !onlyView && !viewInPIP) {
    return null;
  }

  const transactionService = TransactionService(socket, TransactionError);
  const control = ControlServiceFactory({
    socket,
    TransactionService: transactionService,
    KeycodesMapped: keyCodeMapped,
  }).create(device, device.channel);

  const scope = {
    device: () => device,
    control: () => control,
    socket,
    transactionService,
  };

  return (
    <div className='flex justify-center gap-3 h-full'>
      {device.display ? (
        <DeviceScreen
          scope={scope}
          ScalingService={ScalingService()}
          VendorUtil={VendorUtil()}
          PageVisibilityService={PageVisibilityService()}
          zoomOutRatio={zoomOutRatio}
          reconnect={reconnect}
          onlyView={onlyView}
        />
      ) : (
        <div className='flex-col gap-2 text-white w-full h-full flex justify-center items-center absolute top-0 left-0 text-xs bg-black bg-opacity-65'>
          <UsbOffOutlinedIcon />
          Disconnected
        </div>
      )}
      {showActions && <DeviceActions scope={scope} refetch={refetch} device={device} />}
    </div>
  );
}

export default DeviceWrapper;
