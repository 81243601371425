import { ResponseApi } from 'kikifarmer/api/api-services/interfaces';
import { ResponseData } from '@kikisoftware/utilities';
import { DEFAULT_PAGINATION } from 'kikifarmer/utils/contants';

class HttpUtils {
  static getDataFromHttpResponse<T>(response: ResponseApi<T>) {
    return response.data;
  }

  static handlePagingData<T>(response?: ResponseApi<ResponseData<T>>) {
    return {
      results: response?.data?.results ?? [],
      paging: response?.data?.paging ?? DEFAULT_PAGINATION,
    };
  }
}

export default HttpUtils;
