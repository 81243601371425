import DeviceApiService from 'kikifarmer/features/device/services/device-api.service';
import { notifyError, notifySuccess } from 'kikifarmer/utils/notification';
import { ErrorUtils } from 'kikifarmer/api/api-services/error';
import { useTranslation } from 'react-i18next';

export default function useFiles() {
  const { t } = useTranslation();
  const pushFileToDevice = async (
    file: File & { path?: string },
    { onSuccess, devicePath, deviceId }: { onSuccess?: () => void; devicePath: string; deviceId: string },
  ) => {
    if (!file.path) return;
    try {
      await DeviceApiService.instance().pushFile({
        fileName: file.name,
        filePath: file.path,
        devicePath,
        deviceId,
      });
      notifySuccess(t(`Push ${file.name} success`));
      onSuccess?.();
    } catch (err) {
      ErrorUtils.handleError(err, {
        isErrorInstanceFn: (error) => {
          notifyError(error.data.reason);
        },
      });
    }
  };

  return {
    pushFileToDevice,
  };
}
