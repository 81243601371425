import React from 'react';
import Divider from '@mui/material/Divider';
function SidebarFooterCollapse() {
  return (
    <>
      <Divider />
      <div className='px-5 py-2 w-full flex flex-col items-center mt-4'>
        <div className='flex flex-col items-center gap-2'>
          <div className='mt-0' />
        </div>
      </div>
    </>
  );
}

export default SidebarFooterCollapse;
