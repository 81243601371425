import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import {
  apiAutomationEndpoint,
  apiEndpoints,
  apiFarmEndpoints,
  apiFarmLocalEndpoints,
  apiLocalEndpoint,
  apiSSOEndpoints,
} from 'utils/configs';
import { ErrorApi } from 'kikifarmer/api/api-services/error';
import { ResponseError } from 'kikifarmer/api/api-services/interfaces';

class HttpClient {
  protected readonly instance: AxiosInstance;

  constructor(baseURL?: string) {
    this.instance = axios.create({
      baseURL,
    });

    this.configResponseInterceptor();
  }

  private configResponseInterceptor() {
    this.instance.interceptors.response.use(this.handleResponse, this.handleError);
  }

  protected handleResponse(response: AxiosResponse) {
    const { data } = response;
    if (data && (data.success === false || data.success === 'false')) {
      return Promise.reject(new ErrorApi(response));
    }

    return Promise.resolve(response);
  }

  protected handleError(error: AxiosError<ResponseError>) {
    if (!error.response) return Promise.reject(error);
    return Promise.reject(new ErrorApi(error.response));
  }

  setAuthorization(accessToken: string) {
    this.instance.defaults.headers.common.authorization = accessToken;
  }

  clearAuthorization() {
    delete this.instance.defaults.headers.common.authorization;
  }

  getInstance() {
    return this.instance;
  }
}

class HttpServers {
  appServerInstance: HttpClient;
  ssoServerInstance: HttpClient;
  localServerInstance: HttpClient;
  farmServerInstance: HttpClient;
  farmServerLocalInstance: HttpClient;
  farmAutomationLocalInstance: HttpClient;

  appServer: AxiosInstance;
  ssoServer: AxiosInstance;
  localServer: AxiosInstance;
  farmServer: AxiosInstance;
  farmServerLocal: AxiosInstance;
  farmAutomationLocal: AxiosInstance;

  constructor() {
    this.appServerInstance = new HttpClient(apiEndpoints);
    this.ssoServerInstance = new HttpClient(apiSSOEndpoints);
    this.localServerInstance = new HttpClient(apiLocalEndpoint);
    this.farmServerInstance = new HttpClient(apiFarmEndpoints);
    this.farmServerLocalInstance = new HttpClient(apiFarmLocalEndpoints);
    this.farmAutomationLocalInstance = new HttpClient(apiAutomationEndpoint);

    this.appServer = this.appServerInstance.getInstance();
    this.ssoServer = this.ssoServerInstance.getInstance();
    this.localServer = this.localServerInstance.getInstance();
    this.farmServer = this.farmServerInstance.getInstance();
    this.farmServerLocal = this.farmServerLocalInstance.getInstance();
    this.farmAutomationLocal = this.farmAutomationLocalInstance.getInstance();
  }

  setAuthorization(accessToken: string) {
    this.appServerInstance.setAuthorization(accessToken);
    this.ssoServerInstance.setAuthorization(accessToken);
    this.localServerInstance.setAuthorization(accessToken);
    this.farmServerInstance.setAuthorization(accessToken);
    this.farmServerLocalInstance.setAuthorization(accessToken);
    this.farmAutomationLocalInstance.setAuthorization(accessToken);
  }

  clearAuthorization() {
    this.appServerInstance.clearAuthorization();
    this.ssoServerInstance.clearAuthorization();
    this.localServerInstance.clearAuthorization();
    this.farmServerInstance.clearAuthorization();
    this.farmServerLocalInstance.clearAuthorization();
    this.farmAutomationLocalInstance.clearAuthorization();
  }
}

const http = new HttpServers();
export default http;
