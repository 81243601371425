export const googleAnalyticsConfig = import.meta.env.VITE_APP_GA_MEASUREMENT_ID;
export const apiEndpoints = import.meta.env.VITE_APP_REMOTE_SERVER;
export const apiFarmEndpoints = import.meta.env.VITE_APP_FARM_REMOTE_SERVER;
export const apiFarmLocalEndpoints = import.meta.env.VITE_APP_FARM_LOCAL_SERVER;

export const isDevelopment = import.meta.env.VITE_APP_ENV === 'development';
export const isStaging = import.meta.env.VITE_APP_ENV === 'staging';
export const isProduction = import.meta.env.VITE_APP_ENV === 'production';

export const apiLogsEndpoints = import.meta.env.VITE_APP_LOGS_API_URL;
export const apiOlympusEndpoints = import.meta.env.VITE_APP_OLYMPUS_SERVER;
export const apiSSOEndpoints = import.meta.env.VITE_APP_SSO_BACKEND_URL;
export const apiLocalEndpoint = import.meta.env.VITE_APP_LOCAL_SERVER;
export const apiAutomationEndpoint = import.meta.env.VITE_APP_AUTOMATION_SERVER;
export const adsApiEndpoint = import.meta.env.VITE_APP_HYPERION_API_URL;
export const antiCheckEndpoint = import.meta.env.VITE_APP_ANTICHECK_ENDPOINT;

export const farmLiveViewUrl = import.meta.env.VITE_APP_FARM_URL;
export const secretKey = import.meta.env.VITE_APP_SECRET_KEY;

export const middleWareError = 'MIDDLEWARE_INVALID_SESSION';

export const ssoClientUrl = import.meta.env.VITE_APP_SSO_CLIENT_URL;
export const appClientUrl = import.meta.env.VITE_APP_CLIENT_URL;
export const apiLocal = import.meta.env.VITE_APP_LOCAL_SERVER;
export const socketUrl = import.meta.env.VITE_APP_SOCKET_API_URL;
export const affiliateUrl = import.meta.env.VITE_APP_KIKI_AFFILIATE_URL;
export const sentryDSN = import.meta.env.VITE_APP_SENTRY_DSN;
export const localSocketURL = import.meta.env.VITE_APP_LOCAL_SOCKET_URL;

export const walletConnectProjectId = import.meta.env.VITE_APP_WALLET_CONNECT_PROJECT_ID;
export const appV1ClientUrl = import.meta.env.VITE_APP_CLIENT_V1_URL;
export const isGAAllowance = import.meta.env.VITE_APP_GA_ALLOWANCE === 'true';
export const postHogKey = import.meta.env.VITE_APP_POSTHOG_KEY;
export const postHogHost = import.meta.env.VITE_APP_POSTHOG_HOST;
export const env = import.meta.env.VITE_APP_ENV || 'production';
