import { HttpStatusCode } from 'axios';
import { ErrorApi } from 'kikifarmer/api/api-services/error';

// Check if error status code is 401
export const isUnauthorizedError = (error: ErrorApi) => {
  return error.data.code === HttpStatusCode.Unauthorized || error.status === HttpStatusCode.Unauthorized;
};

// Check if error status code is 422
export const isUnprocessableEntityError = (error: ErrorApi) =>
  error.data.code === HttpStatusCode.UnprocessableEntity || error.status === HttpStatusCode.UnprocessableEntity;

// Check if error status code is 404
export const isNotFoundError = (error: ErrorApi) =>
  error.data.code === HttpStatusCode.NotFound || error.status === HttpStatusCode.NotFound;
