import AuthService from 'kikifarmer/features/auth/services/auth.service';
import { setSession } from '@kikisoftware/utilities';
import { env } from 'kikifarmer/utils/configs';
import { PATH_AUTH, PATH_DASHBOARD } from 'kikifarmer/routes/path';
import { notifyError } from 'kikifarmer/utils/notification';
import { useNavigate } from 'react-router-dom';
import useLocales from 'kikifarmer/hooks/useLocales';
import http from 'kikifarmer/api/api-services/http';
import UserApiService from 'kikifarmer/features/user/services/user-api.service';
import { useUserStore } from 'kikifarmer/features/user/store/user.store';

export default function useAuth() {
  const navigate = useNavigate();
  const { currentLang } = useLocales();
  const setUser = useUserStore((state) => state.setUser);

  const login = async (sessionId: string, redirectPath?: string) => {
    try {
      const userInfo = await AuthService.instance().authorization(sessionId);
      if (userInfo?.token) {
        const userToken = userInfo?.token;
        setSession(userToken, env);
        http.setAuthorization(userToken);
        localStorage.setItem('authenticated', userToken);
        if (redirectPath) {
          navigate(redirectPath);
        } else {
          navigate(PATH_DASHBOARD.devices.fullPath);
        }
      } else {
        if (window.self === window.top) {
          // window.location.href = `${PATH_AUTH.login.fullPath}&lang=${currentLang.value}`;
        }
        notifyError('Missing token');
      }
    } catch (err) {
      if (window.self === window.top) {
        // window.location.href = `${PATH_AUTH.login.fullPath}&lang=${currentLang.value}`;
      }
      if (err instanceof Error) {
        notifyError(err.message || 'Something went wrong');
      }
    }
  };

  const getUserProfile = async () => {
    const result = await UserApiService.instance().me();
    console.log('res', result);
    setUser(result.data);
  };

  return {
    login,
    getUserProfile,
  };
}
