import dayjs from 'dayjs';

const S_IFMT = 0o0170000; // Bit mask for checking file types
const S_IFDIR = 0o040000; // Directory type
const S_IFLNK = 0o120000; // Symlink type

export const formatPermissionMode = (mode: number | null): string => {
  if (mode !== null) {
    const res: string[] = [];
    const s = ['x', 'w', 'r'];
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        if (((mode >> (i * 3 + j)) & 1) !== 0) {
          res.unshift(s[j]);
        } else {
          res.unshift('-');
        }
      }
    }
    if ((mode & S_IFMT) === S_IFDIR) {
      res.unshift('d');
    } else if ((mode & S_IFMT) === S_IFLNK) {
      res.unshift('l');
    } else {
      res.unshift('-');
    }
    return res.join('');
  }
  return '';
};

export const fileIsDir = (mode: string | null): boolean => {
  if (mode !== null) {
    const parsedMode = parseInt(mode, 10);
    return (parsedMode & S_IFMT) === S_IFDIR || (parsedMode & S_IFMT) === S_IFLNK;
  }
  return false;
};

export const formatFileSize = (size: number): string => {
  if (size < 1024) {
    return `${size} B`;
  } else if (size >= 1024 && size < 1024 * 1024) {
    return `${Math.round(size / 1024)} Kb`;
  } else {
    return `${Math.round(size / (1024 * 1024))} Mb`;
  }
};

export const formatFileDate = (inputString: string): string => {
  return dayjs(inputString).format('DD/MM/YYYY');
};
