import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import { useTranslation } from 'react-i18next';
import { useCollapseDrawer } from 'kikifarmer/layouts/CollapseDrawerProvider';
import { Link } from 'react-router-dom';
import appRoutes from 'kikifarmer/routes/path';
import { Image } from '@kikisoftware/uikit';
import MuiLink from '@mui/material/Link';
function SidebarHeader() {
  const { t } = useTranslation();
  const { onToggleCollapse } = useCollapseDrawer();

  return (
    <>
      <div className='flex justify-between items-center px-2 relative'>
        <MuiLink component={Link} to={appRoutes.devices.fullPath} underline='none'>
          <div className='flex gap-2 items-center relative'>
            <Image
              src='https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1717754155532'
              sx={{ width: 20 }}
            />
            <Typography variant='h6' color='text.primary'>
              KIKIFARM
            </Typography>
          </div>
        </MuiLink>
        <Button
          variant='outlined'
          color='inherit'
          sx={{ width: 26, height: 26, minWidth: 26, display: { xs: 'none', lg: 'flex' } }}
          onClick={onToggleCollapse}
        >
          <KeyboardDoubleArrowLeftOutlinedIcon color='primary' fontSize='small' />
        </Button>
      </div>
    </>
  );
}

export default SidebarHeader;
