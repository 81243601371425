import React, { useEffect, useState } from 'react';
import DeviceWrapper from 'kikifarmer/features/device/ui/DeviceWrapper.jsx';
import DeviceQuery from 'kikifarmer/features/device/queries/device.query';
import http from 'kikifarmer/api/api-services/http';

function TestDevices(props) {
  const { data: assignedDevices } = DeviceQuery.instance().assignedDevicesQuery();
  const { data: devices } = DeviceQuery.instance().devicesListQuery();

  console.log('data', { devices, assignedDevices });

  if (assignedDevices?.data?.results?.length === 0) return null;

  return (
    <div className='container mx-auto text-center'>
      {assignedDevices.map((device, idx) => (
        <DeviceWrapper key={idx} device={device} showActions={false} zoomOutRatio={4} refetch={null} onlyView />
      ))}
    </div>
  );
}

export default TestDevices;
