import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLayoutProvider } from './LayoutProvider';
import { Outlet } from 'react-router-dom';
import { useCollapseDrawer } from 'kikifarmer/layouts/CollapseDrawerProvider';
import DashboardNavbar from 'kikifarmer/components/navbar/DashboardNavar';
import DashboardSidebar from 'kikifarmer/components/sidebar/DashboardSidebar';
import ScrollToTop from 'kikifarmer/components/ScrollToTop';
import AuthProvider from 'kikifarmer/layouts/AuthProvider';

const APP_BAR_MOBILE = 72;
const APP_BAR_DESKTOP = 60;

const RootStyle = styled('div')({
  display: 'flex',
  width: '100%',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')<{ showHeader: boolean }>(({ theme, showHeader }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  // paddingTop: 0,
  paddingTop: showHeader ? APP_BAR_MOBILE + 24 : 0,
  paddingBottom: 0,
  background: theme.palette.background.layout,
  [theme.breakpoints.up('lg')]: {
    paddingTop: showHeader ? APP_BAR_DESKTOP + 40 : 0,
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

const NewLayout = () => {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const { showHeader } = useLayoutProvider();

  const openSidebar = useCallback(() => {
    setOpen(true);
  }, []);

  console.log('collapseClick', collapseClick);

  return (
    <>
      <AuthProvider>
        <ScrollToTop />
        <RootStyle>
          <DashboardNavbar showHeader={showHeader} onOpenSidebar={openSidebar} />
          <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

          <MainStyle
            className='container mx-auto px-6 pb-6'
            showHeader={showHeader}
            sx={{
              transition: theme.transitions.create('margin', {
                duration: theme.transitions.duration.complex,
              }),
              ...(collapseClick && {
                ml: '72px !important',
              }),
              // background: 'url("https://oasis.app/static/img/background/top_bg.png")',
              // backgroundSize: 'cover',
              // backgroundAttachment: 'fixed',
              // backgroundPosition: '70% 100%',
              // backgroundRepeat: 'no-repeat',
            }}
          >
            <Outlet />
          </MainStyle>
        </RootStyle>
      </AuthProvider>
    </>
  );
};

export default NewLayout;
