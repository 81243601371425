import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { LicenseInfo } from '@mui/x-license-pro';

import 'kikifarmer/styles/index.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'kikifarmer/locales/i18n';
import 'reactflow/dist/style.css';
import { HttpUtils } from '@kikisoftware/utilities';
import { env } from 'kikifarmer/utils/configs';

HttpUtils.configEnv(env);

LicenseInfo.setLicenseKey(
  '05dbf95d84344aac50970d054817aa0dTz1NVUktMTIzLEU9MTkwOTA1MzkzNTAwMCxTPXByZW1pdW0sTE09cGVycGV0dWFsLEtWPTI=',
);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <HelmetProvider>
    <App />
  </HelmetProvider>,
);
