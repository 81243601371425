import { authServices } from '@kikisoftware/utilities';

let authApiService: AuthService;

class AuthService {
  async authorization(sessionId: string) {
    return authServices.ssoLogin({
      sessionId,
      serviceCode: 'kikifarm',
    });
  }

  static instance() {
    if (!authApiService) {
      authApiService = new AuthService();
    }
    return authApiService;
  }
}

export default AuthService;
