import { flagImageURL } from 'kikifarmer/utils/contants';
import { customAlphabet } from 'nanoid';
import { DataGridColDef } from '@kikisoftware/uikit-table-v2';

export const alphabetNanoid = customAlphabet('ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789', 10);

export function getFlagIcon(flag: string) {
  return `${flagImageURL}${flag.toLowerCase()}.svg`;
}

export function formatOrder(order: number) {
  if (order < 10) {
    return `0${order}`;
  }
  return `${order}`;
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function getValueFromObjectByField<T, K extends keyof T>(obj: T, field: string) {
  const fields = field.split('.');
  let value: any = { ...obj };
  for (const f of fields) {
    value = value[f as K];
  }
  return value;
}

export function mergeTwoArrays<T>(arr1: T[], arr2: T[], field: keyof T) {
  console.log('arr1', { arr1, arr2 });
  const arr = [];
  for (let i = 0; i < arr1.length; i++) {
    const index = arr2.findIndex((item) => item[field] === arr1[i][field]);
    console.log('merge index', index);
    // if (index !== -1) {
    arr.push({ ...arr1[i], ...arr2[index] });
    // }
  }
  return arr;
}

export const getVisibilityColumns = (columns: DataGridColDef[]) => {
  return columns.reduce((acc, curr) => ({ ...acc, [curr.field]: curr.visibility ?? false }), {});
};
