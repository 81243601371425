import React, { useRef } from 'react';
import Divider from '@mui/material/Divider';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import VolumeDownOutlinedIcon from '@mui/icons-material/VolumeDownOutlined';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import { classNames } from '@kikisoftware/utilities';
import DeviceFileExplorer from 'kikifarmer/features/device/components/DeviceFileExplorer';
import useFiles from 'kikifarmer/hooks/useFiles';
const renderLabel = ({ Icon, label, onClick, key, align = 'left' }) => {
  return (
    <div
      key={key}
      onClick={onClick}
      className={classNames(
        'w-full flex items-center gap-1.5 p-2 hover:bg-blue-50 cursor-pointer',
        align === 'left' ? 'justify-start' : 'justify-center',
      )}
    >
      <Icon sx={{ color: 'grey.500', width: 16 }} />
      {label && <span className='text-gray-500 text-sm'>{label}</span>}
    </div>
  );
};

function DeviceActions({ scope, device }) {
  const control = scope.control();
  const [showFileExplorer, setShowFileExplorer] = React.useState(false);
  const [screenHeight, setScreenHeight] = React.useState(0);
  const imageInputRef = useRef(null);
  const { pushFileToDevice } = useFiles();

  const onFileExplorerClick = async () => {
    const deviceScreenEl = document.getElementById(`${device.serial}-display`);
    if (deviceScreenEl) {
      setScreenHeight(deviceScreenEl.height);
    }
    const isInApp = navigator.userAgent.includes('KikiFarm');
    const addedSize = {
      width: isInApp ? 180 + 24 + 10 : 176 + 34,
      height: isInApp ? 43 : 76,
    };
    if (showFileExplorer) {
      window.resizeTo(deviceScreenEl.width + addedSize.width, deviceScreenEl.height + addedSize.height);
    } else {
      window.resizeTo(1400, deviceScreenEl.height + addedSize.height);
    }
    setShowFileExplorer((prev) => !prev);
  };

  const menuActions = [
    {
      key: '1',
      Icon: VolumeUpOutlinedIcon,
      label: 'Vol+',
      onClick: () => control.keyPress('volume_up'),
    },
    {
      key: '2',
      Icon: VolumeDownOutlinedIcon,
      label: 'Vol-',
      onClick: () => control.keyPress('volume_down'),
    },
    {
      key: '3',
      Icon: AddAPhotoOutlinedIcon,
      label: 'Screenshot',
    },
    {
      key: '4',
      Icon: TerminalOutlinedIcon,
      label: 'ADB Command',
    },
    {
      key: '5',
      Icon: RestartAltOutlinedIcon,
      label: 'Reboot',
    },
    {
      key: '6',
      Icon: WifiOutlinedIcon,
      label: 'Wifi setting',
    },
    {
      key: '7',
      Icon: FolderIcon,
      label: 'File explorer',
      onClick: onFileExplorerClick,
    },
    {
      key: '8',
      Icon: AddPhotoAlternateOutlinedIcon,
      label: 'Push images',
      onClick: () => imageInputRef.current?.click(),
    },
  ].map(renderLabel);

  const onSelectImages = async (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      await pushFileToDevice(file, {
        devicePath: '/sdcard/Pictures',
        deviceId: device.serial,
      });
    }
  };

  return (
    <div className='flex gap-2'>
      <div className='flex flex-col w-44 rounded-lg overflow-hidden border border-primary-dark border-solid shrink-0'>
        <div className='py-3'>
          <p className='text-xl text-primary-main font-bold'>{device.marketName}</p>
        </div>
        <Divider sx={{ my: 0, py: 0 }} />
        <div className='flex-grow'>{menuActions}</div>
        <div className='grid grid-cols-3'>
          {renderLabel({ Icon: UndoOutlinedIcon, align: 'center', onClick: () => control.back() })}
          {renderLabel({ Icon: HomeOutlinedIcon, align: 'center', onClick: () => control.home() })}
          {renderLabel({ Icon: MenuOutlinedIcon, align: 'center', onClick: () => control.appSwitch() })}
        </div>
        {/*<div>*/}
        {/*  <button onClick={() => control.back()}>Back</button>*/}
        {/*  <button onClick={() => control.home()}>Home</button>*/}
        {/*  <button onClick={() => control.appSwitch()}>Đa nhiệm</button>*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <button*/}
        {/*    onClick={() =>*/}
        {/*      control.screenshot().then((result) => {*/}
        {/*        console.log('result', result);*/}
        {/*        // location.href = result.body.href + '?download';*/}
        {/*      })*/}
        {/*    }*/}
        {/*  >*/}
        {/*    Screenshot*/}
        {/*  </button>*/}
        {/*  <button onClick={() => control.appSwitch()}>Switch keyboard method</button>*/}
        {/*</div>*/}
      </div>

      {showFileExplorer && <DeviceFileExplorer control={control} device={device} screenHeight={screenHeight} />}
      <input
        ref={imageInputRef}
        type='file'
        multiple
        hidden
        onChange={onSelectImages}
        accept='image/png, image/gif, image/jpeg'
      />
    </div>
  );
}

export default DeviceActions;
