// ----------------------------------------------------------------------
import React, { useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useCollapseDrawer } from 'kikifarmer/layouts/CollapseDrawerProvider';
import SimpleBar from 'simplebar-react';
import Scrollbar from 'kikifarmer/components/Scrollbar';
import NavSection from 'kikifarmer/components/sidebar/NavSection';
import MHidden from 'kikifarmer/components/MHidden';
import sidebarConfig from 'kikifarmer/components/sidebar/SidebarConfig';
import SidebarHeaderCollapse from 'kikifarmer/components/sidebar/SidebarHeaderCollapse';
import SidebarHeader from 'kikifarmer/components/sidebar/SidebarHeader';
import SidebarFooter from 'kikifarmer/components/sidebar/SidebarFooter';
import SidebarFooterCollapse from 'kikifarmer/components/sidebar/SidebarFooterCollapse';

export const DRAWER_WIDTH = 300;
export const COLLAPSE_WIDTH = 72;

export const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}) {
  const location = useLocation();
  const { isCollapse, collapseClick, collapseHover, onHoverEnter, onHoverLeave, onExpand } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        overflow: 'visible',
        overflowY: 'auto',
        paddingX: 0,
        paddingY: 1.5,
        // paddingBottom: "245px",
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        position: 'relative',
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      <Box px={isCollapse ? 0 : 2.5} pt={1.5}>
        {isCollapse ? <SidebarHeaderCollapse /> : <SidebarHeader />}
        <SimpleBar
          style={{
            height: isCollapse ? 'calc(100vh - 410px)' : 'calc(100vh - 180px)',
            marginTop: 10,
          }}
        >
          {/*<Box*/}
          {/*  component='img'*/}
          {/*  src='https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1703737262382'*/}
          {/*  sx={{ width: 140, opacity: 0.05 }}*/}
          {/*  className='absolute -top-10 left-0 right-0 mx-auto'*/}
          {/*/>*/}
          <NavSection
            marginTop={isCollapse ? 0 : 1}
            navConfig={sidebarConfig}
            isShow={!isCollapse}
            onExpand={onExpand}
          />
          <Box sx={{ flexGrow: 1 }} />
        </SimpleBar>
      </Box>
      {isCollapse ? <SidebarFooterCollapse /> : <SidebarFooter />}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        zIndex: 1,
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      <MHidden width='lgUp'>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.sky',
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width='lgDown'>
        <Drawer
          open
          variant='persistent'
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              overflow: 'visible',
              width: DRAWER_WIDTH,
              bgcolor: 'background.sky',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                // @ts-ignore
                boxShadow: (theme) => theme.customShadows?.z20,
                bgcolor: 'background.sky',
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
