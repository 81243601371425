function PageVisibilityService() {
  var service = {
    hidden: false,
  };

  function visibilityChangeListener() {
    service.hidden = document.hidden;
  }

  document.addEventListener('visibilitychange', visibilityChangeListener, false);

  return service;
}

export default PageVisibilityService;
