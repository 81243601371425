import React from 'react';
import Divider from '@mui/material/Divider';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { ButtonIcon, Image, useModal } from '@kikisoftware/uikit';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import RateReviewIcon from '@mui/icons-material/RateReview';
import Box from '@mui/material/Box';

function SidebarFooter() {
  const { t } = useTranslation();

  return (
    <div className='relative'>
      <Divider />
      <div className='px-5 py-2 mt-3'>
        <Box
          sx={{
            background: 'linear-gradient(90deg, rgba(37,43,205,1) 0%, rgba(77,53,213,1) 37%, rgba(143,29,200,1) 100%)',
            p: 2,
            borderRadius: 1,
            color: 'white',
            position: 'relative',
            overflow: 'hidden',
            cursor: 'pointer',
          }}
        >
          <div className='flex gap-2 items-center justify-between'>
            <Typography
              component='p'
              variant='body2'
              color={'white'}
              sx={{ maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {t('Gói của bạn')}
            </Typography>
            <Typography component='p' variant='body1Bold' color={'warning.main'}>
              Premium+
            </Typography>
          </div>

          <div className='flex gap-2 items-center justify-between mt-1'>
            <Typography
              component='p'
              variant='body2'
              color={'text.subColo2'}
              sx={{ maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}
              className='grow'
            >
              {t('Devices')}
            </Typography>
            <Typography component='p' variant='body1Bold' color={'warning.main'}>
              Không giới hạn
            </Typography>
          </div>
          {/*<div className='flex gap-2 items-center justify-between mt-1'>*/}
          {/*  <Typography*/}
          {/*    component='p'*/}
          {/*    variant='body2'*/}
          {/*    color='text.subColo2'*/}
          {/*    sx={{ maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}*/}
          {/*    className='grow'*/}
          {/*  >*/}
          {/*    {t('Thành viên')}*/}
          {/*  </Typography>*/}
          {/*  <PeopleAltIcon fontSize='small' sx={{ color: 'primary.main' }} />*/}
          {/*  <Typography component='p' variant='body1Bold' color={'text.modalTitle'}>*/}
          {/*    {statistic.userInfo.usedMember}/{statistic.package.memberLimit}*/}
          {/*  </Typography>*/}
          {/*</div>*/}
        </Box>
        <Divider sx={{ my: 2 }} />
      </div>
    </div>
  );
}

export default SidebarFooter;
