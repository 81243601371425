import HttpServers from 'kikifarmer/api/api-services/http';
import { DeviceProps } from 'kikifarmer/features/device/interfaces/device.interface';
import http from 'kikifarmer/api/api-services/http';
import { ResponseApi, ResponseData } from '@kikisoftware/utilities';
import { QueryFilterData } from 'kikifarmer/api/api-services/interfaces';

let deviceApiService: DeviceApiService;

class DeviceApiService {
  async getDevices(): Promise<DeviceProps[]> {
    return http.appServer.get('http://localhost:7100/api/v1/devices').then((res) => res.data.devices);
  }

  async createDevice(payload: { serial: string; name: string }) {
    return http.farmServer.post('/api/device/create', payload).then((res) => res.data);
  }

  async deleteDevice(deviceId: string) {
    return http.farmServer.delete(`/api/device/${deviceId}`).then((res) => res.data);
  }

  // async getDevice(deviceId?: string): Promise<DeviceProps> {
  //   return http.appServer.get('http://localhost:7100/api/v1/devices/' + deviceId).then((res) => res.data.device);
  // }

  async getDevice(deviceId?: string): Promise<any> {
    return http.farmServerLocal.get('/api/device/' + deviceId).then((res) => res.data);
  }

  async getAssignedDevices(query: QueryFilterData): Promise<ResponseApi<ResponseData<DeviceProps[]>>> {
    return http.farmServer
      .post<ResponseApi<ResponseData<DeviceProps[]>>>('/api/device/filter', query)
      .then((res) => res.data);
  }

  async getLocalDevices(): Promise<ResponseApi<DeviceProps[]>> {
    return http.farmServerLocal.get('/api/devices').then((res) => res.data);
  }

  async pushFile(data: { fileName: string; filePath: string; devicePath: string; deviceId: string }) {
    return http.farmServerLocal.post('/api/device/push-file', data);
  }

  static instance() {
    if (!deviceApiService) {
      deviceApiService = new DeviceApiService();
    }
    return deviceApiService;
  }
}

export default DeviceApiService;
