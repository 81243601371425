import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { TFunction } from 'i18next';
import { ErrorUtilsHandleErrorOptions, ResponseError } from 'kikifarmer/api/api-services/interfaces';
import {
  isNotFoundError,
  isUnauthorizedError,
  isUnprocessableEntityError,
} from 'kikifarmer/api/api-services/error-types';
import { notifyError } from 'kikifarmer/utils/notification';

export class ErrorApi<T = string> {
  readonly status: number;
  readonly data: ResponseError<T>;
  readonly config: AxiosRequestConfig;

  constructor(response: AxiosResponse<ResponseError<T>>) {
    this.status = response.status;
    this.data = response.data;
    this.config = response.config;
  }
}

export class ErrorUtils {
  static handleError<T = string>(err: unknown, options?: ErrorUtilsHandleErrorOptions<T>) {
    const {
      fn,
      isErrorInstanceFn,
      isNotErrorInstanceFn,
      isUnprocessableEntityErrorFn,
      isNotFoundErrorFn,
      isUnauthorizedErrorFn,
      isSyntaxErrorFn,
      isAxiosErrorFn,
    } = options ?? {};
    if (err instanceof ErrorApi) {
      // do something
      isErrorInstanceFn?.(err);
      if (isUnprocessableEntityError(err)) {
        isUnprocessableEntityErrorFn?.(err);
      }
      if (isNotFoundError(err)) {
        isNotFoundErrorFn?.(err);
      }
      if (isUnauthorizedError(err)) {
        isUnauthorizedErrorFn?.(err);
      }
    } else {
      if (typeof err === 'string') {
        isNotErrorInstanceFn?.(err);
      } else if (err instanceof SyntaxError) {
        isSyntaxErrorFn?.(err);
      } else if (err instanceof AxiosError) {
        isAxiosErrorFn?.(err);
      }
    }
    fn && fn();
  }

  static handleCommonError(err: unknown, t: TFunction<'translation', undefined>) {
    ErrorUtils.handleError(err, {
      isErrorInstanceFn: (error) => {
        notifyError(t(error.data.reason));
      },
    });
  }
}
