export const target = {
  abi: 'x86_64',
  airplaneMode: false,
  battery: {
    health: 'good',
    level: 100,
    scale: 100,
    source: 'ac',
    status: 'charging',
    temp: 32.2,
    voltage: 3.65,
  },
  browser: {
    apps: [
      {
        id: 'com.android.browser/.BrowserActivity',
        name: 'Browser',
        selected: true,
        system: true,
        type: 'android-browser',
        developer: 'Google Inc.',
      },
    ],
    selected: true,
  },
  channel: '+dMhGpFI28OEk/LJBQj8PqTFjWQ=',
  cpuPlatform: 'aosp-user',
  createdAt: '2024-05-30T08:09:40.836Z',
  display: {
    density: 3,
    fps: 60.000003814697266,
    height: 1920,
    id: 0,
    rotation: 0,
    secure: true,
    size: 4.589389801025391,
    url: 'ws://localhost:7468',
    width: 1080,
    xdpi: 480,
    ydpi: 480,
  },
  group: {
    class: 'standard',
    id: '422fb3a3d03a400e8baa3bb342c79021',
    lifeTime: {
      start: '2024-05-30T08:08:14.901Z',
      stop: '2025-05-30T08:08:14.901Z',
    },
    lock: false,
    name: 'Common',
    origin: '422fb3a3d03a400e8baa3bb342c79021',
    originName: 'Common',
    owner: {
      email: 'administrator@fakedomain.com',
      name: 'administrator',
    },
    repetitions: 0,
  },
  logs_enabled: false,
  manufacturer: 'SAMSUNG',
  marketName: 'Pixel XL',
  model: 'SM-G9880',
  network: {
    connected: true,
    failover: false,
    roaming: false,
    subtype: '',
    type: 'WIFI',
  },
  openGLESVersion: '3.1',
  operator: 'Android',
  owner: {
    email: 'administrator@fakedomain.com',
    name: 'administrator',
    group: 'XkJZb7pOQguTTtdbXMH8Xg==',
  },
  phone: {
    iccid: null,
    imei: null,
    imsi: null,
    network: 'LTE',
    phoneNumber: null,
  },
  platform: 'Android',
  presenceChangedAt: '2024-06-03T02:14:37.870Z',
  present: true,
  product: 'SM-G9880',
  provider: {
    channel: 'PLumEbI6RVW3CZDkIPdqEg==',
    name: 'Admin-PC',
  },
  ready: true,
  remoteConnect: false,
  remoteConnectUrl: null,
  reverseForwards: [],
  sdk: '28',
  serial: 'emulator-5554',
  status: 3,
  statusChangedAt: '2024-06-03T02:14:37.759Z',
  usage: null,
  usageChangedAt: '2024-05-30T10:42:03.508Z',
  version: '9',
  using: true,
  usable: true,
  state: 'using',
  enhancedName: 'Pixel XL',
  enhancedModel: 'SM-G9880',
  enhancedImage120: '/static/app/devices/icon/x120/_default.jpg',
  enhancedImage24: '/static/app/devices/icon/x24/_default.jpg',
  enhancedStateAction: 'Stop Using',
  enhancedStatePassive: 'Using',
  enhancedBatteryPercentage: '100%',
  enhancedBatteryHealth: 'Good',
  enhancedBatterySource: 'AC',
  enhancedBatteryStatus: 'Charging',
  enhancedBatteryTemp: '32.2°C',
  enhancedGroupOwnerProfileUrl: 'mailto:administrator@fakedomain.com',
  likelyLeaveReason: 'owner_change',
  enhancedUserProfileUrl: 'mailto:administrator@fakedomain.com',
  enhancedUserName: 'administrator',
};

export const channel = '+dMhGpFI28OEk/LJBQj8PqTFjWQ=';

export const AppState = {
  config: {
    websocketUrl: 'http://localhost:7110/?uip=%3A%3A1',
  },
  user: {
    createdAt: '2024-05-28T09:39:12.316Z',
    email: 'administrator@fakedomain.com',
    forwards: [],
    group: 'GvGfPAvzSkWDJv/stXsg6w==',
    groups: {
      lock: false,
      quotas: {
        allocated: {
          duration: 1296000000,
          number: 5,
        },
        consumed: {
          duration: 0,
          number: 1,
        },
        defaultGroupsDuration: 1296000000,
        defaultGroupsNumber: 5,
        defaultGroupsRepetitions: 10,
        repetitions: 10,
      },
      subscribed: ['3e55743e58404f5b944ff7d45eba2738'],
    },
    ip: '127.0.0.1',
    lastLoggedInAt: '2024-05-28T09:39:12.316Z',
    name: 'administrator',
    privilege: 'admin',
    settings: {
      deviceListActiveTabs: {
        details: true,
        icons: false,
      },
      lastUsedDevice: 'emulator-5556',
    },
  },
  device: {
    platform: 'Android',
  },
};

export const keyCodeMapped = {
  8: 'del',
  9: 'tab',
  13: 'enter',
  20: 'caps_lock',
  27: 'escape',
  33: 'page_up',
  34: 'page_down',
  35: 'move_end',
  36: 'move_home',
  37: 'dpad_left',
  38: 'dpad_up',
  39: 'dpad_right',
  40: 'dpad_down',
  45: 'insert',
  46: 'forward_del',
  93: 'menu',
  112: 'f1',
  113: 'f2',
  114: 'f3',
  115: 'f4',
  116: 'f5',
  117: 'f6',
  118: 'f7',
  119: 'f8',
  120: 'f9',
  121: 'f10',
  122: 'f11',
  123: 'f12',
  144: 'num_lock',
};
