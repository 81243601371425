import React from 'react';
import { Svg } from '@kikisoftware/uikit';
import appRoutes from 'kikifarmer/routes/path';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import PhonelinkSetupOutlinedIcon from '@mui/icons-material/PhonelinkSetupOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
export interface SidebarItem {
  title: string;
  path: string;
  icon?: React.ReactNode;
  hideWithMember?: boolean;
  isHidden?: boolean;
  info?: any;
  children?: SidebarItem[];
  onlyDev?: boolean;
}

export interface SidebarItems {
  subheader?: string;
  items: SidebarItem[];
}

const getIcon = (name: string) => (
  <Svg src={`/static/sidebar-icons/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

export const ICONS = {
  allProfile: getIcon('all-profiles'),
  member: getIcon('member'),
  automation: getIcon('automation'),
  myAccount: getIcon('my-account'),
  affiliate: getIcon('affiliate'),
  support: getIcon('support'),
  farm: getIcon('farm'),
  wallet: getIcon('payment-center'),
  setting: getIcon('setting'),
  plugin: getIcon('plugin'),
  historyLog: getIcon('history-log'),
  extension: getIcon('extension'),
  adsReports: getIcon('ico-ads-reports'),
  apollo: getIcon('icoApollo'),
  sync: getIcon('sync'),
};

// const sidebarConfig = (userInfo?: UserInfoProps): SidebarItems[] => [
const sidebarConfig: SidebarItems[] = [
  {
    items: [
      {
        title: 'Devices Controller',
        path: appRoutes.devices.fullPath,
        icon: <ImportantDevicesIcon sx={{ width: 20 }} />,
      },
      {
        title: 'Devices Management',
        path: appRoutes.devicesManagement.fullPath,
        icon: <PhonelinkSetupOutlinedIcon sx={{ width: 20 }} />,
      },
      {
        title: 'Automation',
        path: appRoutes.automation.fullPath,
        icon: ICONS.automation,
      },
      // {
      //   title: 'App manager',
      //   path: appRoutes.common.fullPath,
      //   icon: <AppsIcon sx={{ width: 20 }} />,
      // },
      // {
      //   title: 'Account manager',
      //   path: appRoutes.common.fullPath,
      //   icon: ICONS.allProfile,
      // },
      // {
      //   title: 'Store',
      //   path: appRoutes.common.fullPath,
      //   icon: <LocalGroceryStoreOutlinedIcon sx={{ width: 20 }} />,
      // },
      // {
      //   title: 'Backup',
      //   path: appRoutes.common.fullPath,
      //   icon: <BackupOutlinedIcon sx={{ width: 20 }} />,
      // },
    ],
  },
];

export default sidebarConfig;
