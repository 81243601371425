import React, { useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import SimpleBar from 'simplebar-react';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  fileIsDir,
  formatFileDate,
  formatFileSize,
  formatPermissionMode,
} from 'kikifarmer/features/device/utils/device-file-explorer.util';
import TextField from '@mui/material/TextField';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import Button from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { classNames } from '@kikisoftware/utilities';
import DeviceApiService from 'kikifarmer/features/device/services/device-api.service';
import { notifyError, notifySuccess } from 'kikifarmer/utils/notification';
import { ErrorUtils } from 'kikifarmer/api/api-services/error';
import { DeviceFile } from 'kikifarmer/features/device/interfaces/device-file.interface';
import useFiles from 'kikifarmer/hooks/useFiles';

const Scrollbar = styled(SimpleBar)({
  '& .simplebar-content': {
    height: '100%',
    // overflow: 'hidden',
  },
});

function DeviceFileExplorer({ control, device, screenHeight }: any) {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [files, setFiles] = useState<DeviceFile[]>([]);
  // const [paths, setPaths] = useState([]);
  const pathsRef = useRef<string[]>([]);
  const [dragging, setDragging] = useState(false);
  const timeoutDrag = useRef<ReturnType<typeof setTimeout>>();
  const { pushFileToDevice } = useFiles();

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!dragging) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (timeoutDrag.current) {
      clearTimeout(timeoutDrag.current);
    }

    timeoutDrag.current = setTimeout(() => {
      if (dragging) {
        setDragging(false);
      }
    }, 500);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    const files = Array.from(e.dataTransfer.files);
    console.log('files', files);
    // if (files.length > 0) {
    //   const file = files[0];
    //   await uploadFileToAndroid(file);
    // }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      await pushFileToDevice(file, {
        onSuccess: () => {
          listDir();
        },
        devicePath: search,
        deviceId: device.serial,
      });
    }
  };

  const getAbsolutePath = () => {
    return ('/' + pathsRef.current.join('/')).replace(/\/\/+/g, '/');
  };

  const resetPaths = (path: string) => {
    // setPaths(path.split('/'));
    pathsRef.current = path.split('/');
  };

  const listDir = () => {
    const path = getAbsolutePath();
    setSearch(path);

    control
      .fslist(path)
      .then((result: { body: DeviceFile[] }) => {
        setFiles(result.body);
      })
      .catch((err: Error) => {
        throw new Error(err.message);
      });
  };

  const dirEnterLocation = () => {
    if (search) {
      resetPaths(search);
      listDir();
      setSearch(getAbsolutePath());
    }
  };

  const dirEnter = (name: string) => {
    if (name) {
      // setPaths((prevPaths) => [...prevPaths, name]);
      pathsRef.current = [...pathsRef.current, name];
    }
    listDir();
    setSearch(getAbsolutePath());
  };

  const dirUp = () => {
    if (pathsRef.current.length !== 0) {
      // setPaths((prevPaths) => prevPaths.slice(0, -1));
      pathsRef.current = pathsRef.current.slice(0, -1);
    }
    listDir();
    setSearch(getAbsolutePath());
  };

  const getFile = (file: string) => {
    const path = getAbsolutePath() + '/' + file;
    control
      .fsretrieve(path)
      .then((result: { body: { href: string } }) => {
        if (result.body) {
          window.open('http://localhost:7100' + result.body.href + '?download', '_blank');
        }
      })
      .catch((err: Error) => {
        throw new Error(err.message);
      });
  };

  // Initialize
  useEffect(() => {
    listDir();
  }, []);

  const _files = files.map((file: any) => ({
    ...file,
    isDir: fileIsDir(file.mode),
    size: fileIsDir(file.mode) ? '-' : formatFileSize(file.size),
    date: formatFileDate(file.mtime),
    permission: formatPermissionMode(file.mode),
  }));

  return (
    <div>
      <div className='flex items-center gap-3 h-14'>
        <Button onClick={dirUp}>
          <KeyboardReturnIcon fontSize='small' />
        </Button>
        <TextField size='small' type='text' value={search} onChange={(e) => setSearch(e.target.value)} />
        <Button onClick={dirEnterLocation}>
          <PlayArrowIcon fontSize='small' />
        </Button>
      </div>
      <Scrollbar style={{ height: screenHeight - 56, width: '100%', minWidth: 700 }}>
        <div
          onDragEnter={handleDragEnter}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
          onDragLeave={handleDragLeave}
          className='h-full relative'
        >
          <div
            className={classNames(
              'absolute top-0 left-0 bg-primary-main bg-opacity-30 w-full h-full flex justify-center items-center pointer-events-none',
              dragging ? '' : 'hidden',
            )}
          >
            {t("Drag and Drop here to send to this device's path")}
          </div>

          <table className='table-auto w-full'>
            <thead>
              <tr className='text-grey-500'>
                <th align='left'>Name</th>
                <th>Size</th>
                <th>Date</th>
                <th>Permissions</th>
              </tr>
            </thead>
            <tbody>
              {_files
                .sort((a, b) => {
                  if (a.isDir !== b.isDir) {
                    return a.isDir ? -1 : 1;
                  }
                  return a.name.localeCompare(b.name);
                })
                .map((file) => (
                  <tr key={nanoid(8)}>
                    <td
                      className='hover:text-primary-main text-sm text-left cursor-pointer text-blue-400 flex items-center gap-2'
                      onClick={() => (file.isDir ? dirEnter(file.name) : getFile(file.name))}
                    >
                      {fileIsDir(file.mode) ? (
                        <FolderIcon fontSize='small' />
                      ) : (
                        <InsertDriveFileIcon fontSize='small' />
                      )}
                      <span title={file.name} className='max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap'>
                        {file.name}
                      </span>
                    </td>
                    <td className='text-sm text-grey-500'>{file.size}</td>
                    <td className='text-sm text-grey-500'>{file.date}</td>
                    <td className='text-sm text-grey-500'>{file.permission}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Scrollbar>
    </div>
  );
}

export default DeviceFileExplorer;
