import { useMutation, useQuery } from '@tanstack/react-query';
import queryKeys from 'kikifarmer/utils/query-keys';
import DeviceApiService from 'kikifarmer/features/device/services/device-api.service';
import { DeviceProps } from 'kikifarmer/features/device/interfaces/device.interface';
import { ResponseApi, ResponseData } from '@kikisoftware/utilities';
import HttpUtils from 'kikifarmer/api/api-services/httpUtils';
import { QueryFilterData } from 'kikifarmer/api/api-services/interfaces';
import { MutationActions } from 'kikifarmer/interfaces/api.interface';
import { DefaultError } from '@tanstack/react-query/build/modern';

let deviceQuery: DeviceQuery;

class DeviceQuery {
  devicesListQuery() {
    const query = useQuery<ResponseApi<DeviceProps[]>>({
      queryKey: [queryKeys.devicesList],
      queryFn: () => DeviceApiService.instance().getLocalDevices(),
      initialData: { data: [], success: false, code: -1, moreInfo: { env: 'initial' } },
    });

    return [query?.data?.data ?? [], query] as const;
  }

  deviceQuery(deviceId?: string) {
    return useQuery<any>({
      queryKey: [queryKeys.device, deviceId],
      queryFn: () => DeviceApiService.instance().getDevice(deviceId),
      initialData: undefined,
    });
  }

  assignedDevicesQuery(queryParams: QueryFilterData) {
    const query = useQuery<ResponseApi<ResponseData<DeviceProps[]>>>({
      queryKey: [queryKeys.assignedDevices],
      queryFn: () => DeviceApiService.instance().getAssignedDevices(queryParams),
    });

    return [HttpUtils.handlePagingData(query.data), query] as const;
  }

  getUnassignedDevices() {
    const [localDevices, { isFetching: localDeviceFetching, refetch }] = this.devicesListQuery();
    const [assignedDevices, { isFetching: assignedDeviceFetching }] = this.assignedDevicesQuery({
      page: 1,
      limit: 1000,
    });

    return {
      devices: localDevices?.filter((d) => !assignedDevices.results.find((ad) => ad.serial === d.serial)) ?? [],
      isLoading: localDeviceFetching || assignedDeviceFetching,
      refetch,
    };
  }

  assignDevice({
    onSuccess,
    onError,
  }: MutationActions<DeviceProps, { serial: string; name: string }, DefaultError> = {}) {
    const { mutateAsync, isPending } = useMutation({
      mutationFn: DeviceApiService.instance().createDevice,
      onSuccess,
      onError,
    });

    const handler = (payload: { serial: string; name: string }) => async () => mutateAsync(payload);

    return [handler, isPending] as const;
  }

  removeAssignedDevice({ onSuccess, onError }: MutationActions<DeviceProps, string, DefaultError> = {}) {
    const { mutateAsync, isPending } = useMutation({
      mutationFn: DeviceApiService.instance().deleteDevice,
      onSuccess,
      onError,
    });

    const handler = (deviceId: string) => async () => mutateAsync(deviceId);

    return [handler, isPending] as const;
  }

  static instance() {
    if (!deviceQuery) {
      deviceQuery = new DeviceQuery();
    }
    return deviceQuery;
  }
}

export default DeviceQuery;
