import { TFunction } from 'i18next';

export const flagImageURL = 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/';
export const TABLE_SETTING_FIELD = 'tableSettings';

export const DEFAULT_PAGINATION = {
  page: 1,
  limit: 20,
  total: 0,
};

export const defaultToolbarTitle = (t: TFunction<'translation', undefined>) => ({
  columnsAdjust: t('columnsAdjust'),
  refresh: t('refresh'),
  findBy: t('findBy'),
  findColumnAdjust: t('findColumnAdjust'),
  gotoPage: t('gotoPage'),
  labelRowPerPage: t('labelRowPerPage'),
  labelDisplayPagination: t('labelDisplayPagination'),
});

export const DEFAULT_ROW_PER_PAGE_OPTIONS = [5, 10, 20, 50, 200, 500];
