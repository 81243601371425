import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DeviceQuery from 'kikifarmer/features/device/queries/device.query';
import DeviceWrapper from 'kikifarmer/features/device/ui/DeviceWrapper';
import PageWrapper from 'kikifarmer/layouts/PageWrapper';

function DeviceControllerTestView() {
  const { deviceId } = useParams();
  const { data, refetch } = DeviceQuery.instance().deviceQuery(deviceId);
  console.log('data', data);

  if (!data?.data?.channel) return null;

  return (
    <PageWrapper title={data ? data.marketName : 'Device Controller'}>
      <div className='container mx-auto text-center'>
        <DeviceWrapper device={data.data} refetch={refetch} />
      </div>
    </PageWrapper>
  );
}

export default DeviceControllerTestView;
