import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate, useLocation, useRoutes } from 'react-router-dom';
import appRoutes from 'kikifarmer/routes/path';
import DashboardLayout from 'kikifarmer/layouts/DashboardLayout';
import TestDevices from 'kikifarmer/features/test-devices/TestDevices';
import DeviceControllerTestView from 'kikifarmer/features/test-devices/DeviceControllerTestView';

const Loadable =
  <C extends React.ComponentType<any>, P extends React.ComponentProps<C>>(Component: C) =>
  (props: P) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();
    const isDashboard = pathname.includes('/dashboard');

    return (
      <Suspense
        fallback={
          // <AppLoadingScreen
          //   sx={{
          //     ...(!isDashboard && {
          //       top: 0,
          //       left: 0,
          //       width: 1,
          //       zIndex: 9999,
          //       position: 'fixed',
          //     }),
          //   }}
          // />
          <></>
        }
      >
        {/*@ts-ignore*/}
        <Component {...props} />
      </Suspense>
    );
  };

const Devices = Loadable(lazy(() => import('kikifarmer/pages/dashboard/Devices')));
const AssignedDevices = Loadable(lazy(() => import('kikifarmer/pages/dashboard/DeviceManagement/AssignedDevices')));
const UnassignedDevices = Loadable(lazy(() => import('kikifarmer/pages/dashboard/DeviceManagement/UnassignedDevices')));
const DeviceController = Loadable(lazy(() => import('kikifarmer/pages/dashboard/DeviceController')));
const AutomationEditor = Loadable(lazy(() => import('kikifarmer/pages/dashboard/AutomationEditor')));
const Automation = Loadable(lazy(() => import('kikifarmer/pages/dashboard/Automation/Automation')));

const Authorization = Loadable(lazy(() => import('kikifarmer/pages/auth/Authorization')));

export const appRouter = createBrowserRouter([
  {
    path: 'dashboard',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        // element: <Dashboard />,
        element: <Navigate to={appRoutes.devices.path} replace />,
      },
      {
        path: appRoutes.devices.path,
        element: <Devices />,
      },
      {
        path: appRoutes.devicesManagement.path,
        children: [
          {
            path: appRoutes.unassignedDevicesManagement.path,
            element: <UnassignedDevices />,
          },
          {
            index: true,
            element: <AssignedDevices />,
          },
        ],
      },
      {
        path: 'test-devices',
        element: <TestDevices />,
      },
      {
        path: appRoutes.automation.path,
        children: [
          {
            path: appRoutes.automationEditor.path,
            element: <AutomationEditor />,
          },
          {
            index: true,
            element: <Automation />,
          },
        ],
      },
    ],
  },
  {
    path: appRoutes.deviceController.path + '/:deviceId',
    element: <DeviceController />,
  },
  {
    path: appRoutes.deviceControllerView.path + '/:deviceId',
    element: <DeviceController showActions={false} viewInPIP zoomOutRatio={1.25} />,
  },
  {
    path: 'test-devices' + '/:deviceId',
    element: <DeviceControllerTestView />,
  },
  {
    path: 'auth',
    children: [{ element: <Authorization />, index: true }],
  },
  // {
  //   path: 'auth',
  //   element: <AuthenticationLayout />,
  //   children: [
  //     { element: <SessionChecking />, index: true },
  //     {
  //       path: 'login',
  //       element: <Login />,
  //     },
  //   ],
  // },
  {
    path: '*',
    // element: <LogoOnlyLayout />,
    children: [{ path: '*', element: <Navigate to='/404' replace /> }],
  },
  {
    path: '/',
    element: <Navigate to='/dashboard' replace />,
  },
  { path: '*', element: <Navigate to='/404' replace /> },
]);
