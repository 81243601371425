const modules = ['devicesList', 'assignedDevices', 'listGroup', 'automationDebugLogs'];

type QueryKeys = {
  [K in (typeof modules)[number]]: string;
};

const queryKeys: QueryKeys = modules.reduce((obj, key) => {
  obj[key] = key;
  return obj;
}, {} as QueryKeys);

export default queryKeys;
