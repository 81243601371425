import http from 'kikifarmer/api/api-services/http';

let instance: UserApiService;

class UserApiService {
  async me() {
    return http.farmServer.get('/api/user/me').then((res) => res.data);
  }

  static instance() {
    if (!instance) {
      instance = new UserApiService();
    }
    return instance;
  }
}

export default UserApiService;
