import { GlobalStyles, KikiThemeProvider, ModalProvider, SettingsProvider } from '@kikisoftware/uikit';
import { CollapseDrawerProvider } from 'kikifarmer/layouts/CollapseDrawerProvider';
import ThemeLocalization from 'kikifarmer/components/ThemeLocalization';
import LayoutProvider from 'kikifarmer/layouts/LayoutProvider';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { appRouter } from 'kikifarmer/routes';
import { Toaster } from 'sonner';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      placeholderData: true,
      retry: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <KikiThemeProvider theme='default'>
            <ThemeLocalization>
              <ModalProvider>
                <GlobalStyles />
                <LayoutProvider>
                  <Toaster
                    richColors
                    visibleToasts={9}
                    toastOptions={{
                      className: 'p-4 gap-3',
                    }}
                  />
                  <RouterProvider router={appRouter} />
                </LayoutProvider>
              </ModalProvider>
            </ThemeLocalization>
          </KikiThemeProvider>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </QueryClientProvider>
  );
}

export default App;
