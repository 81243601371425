import React from 'react';
import Button from '@mui/material/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import Divider from '@mui/material/Divider';
import { useCollapseDrawer } from 'kikifarmer/layouts/CollapseDrawerProvider';
import appRoutes from 'kikifarmer/routes/path';
import { Link } from 'react-router-dom';

function SidebarHeaderCollapse() {
  const { onToggleCollapse } = useCollapseDrawer();

  return (
    <div className='flex flex-col items-center gap-3'>
      <Button
        variant='outlined'
        color='inherit'
        sx={{ width: 26, height: 26, minWidth: 26 }}
        onClick={onToggleCollapse}
      >
        <KeyboardDoubleArrowLeftOutlinedIcon color='primary' fontSize='small' sx={{ transform: 'rotate(180deg)' }} />
      </Button>
      <Divider />

      <div className='mt-5'>
        <Link to={appRoutes.devices.fullPath} className='grow'>
          <Button variant='contained' size='small' fullWidth sx={{ mt: 1.25, width: 32, height: 32, minWidth: 32 }}>
            <AddOutlinedIcon />
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default SidebarHeaderCollapse;
