import { enUS, ruRU, viVN } from '@mui/material/locale';
import { getFlagIcon } from 'kikifarmer/utils/helpers';
import { LocalesProps } from 'kikifarmer/locales/constants/types';

export const LANGUAGE_LIST: LocalesProps[] = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: getFlagIcon('us'),
  },
  {
    label: 'Tiếng Việt',
    value: 'vi',
    systemValue: viVN,
    icon: getFlagIcon('vn'),
  },
  {
    label: 'Russian',
    value: 'ru',
    systemValue: ruRU,
    icon: getFlagIcon('ru'),
  },
];
