import { appClientUrl, ssoClientUrl } from 'kikifarmer/utils/configs';

function path(root: string, sublink: string): string {
  return `${root}${sublink}`;
}

const ROOTS = '/';
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_ROOT = {
  root: ROOTS,
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: {
    path: 'login',
    fullPath: `${ssoClientUrl}/auth/login?serviceCode=kikifarm&redirectUrl=${window.location.origin}`,
  },
  signUp: `${ssoClientUrl}/auth/sign-up`,
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  expired: path(ROOTS_AUTH, '/expired'),
};

export const PATH_DASHBOARD = {
  common: {
    path: 'common',
    fullPath: path(ROOTS_DASHBOARD, '/common'),
  },
  devices: {
    path: 'devices-controller',
    fullPath: path(ROOTS_DASHBOARD, '/devices-controller'),
  },
  devicesManagement: {
    path: 'devices-management',
    fullPath: path(ROOTS_DASHBOARD, '/devices-management'),
  },
  unassignedDevicesManagement: {
    path: 'unassigned',
    fullPath: path(ROOTS_DASHBOARD, '/devices-management/unassigned'),
  },
  deviceController: {
    path: 'local-device-controller',
    fullPath: path(ROOTS_DASHBOARD, '/local-device-controller'),
  },
  deviceControllerView: {
    path: 'local-device-controller/view',
    fullPath: path(ROOTS_DASHBOARD, '/local-device-controller/view'),
  },
  automation: {
    path: 'automation',
    fullPath: path(ROOTS_DASHBOARD, '/automation'),
  },
  automationEditor: {
    path: 'editor',
    fullPath: path(ROOTS_DASHBOARD, '/automation/editor'),
  },
};

const appRoutes = {
  ...PATH_ROOT,
  ...PATH_AUTH,
  ...PATH_DASHBOARD,
};

export default appRoutes;
