import React, { useEffect } from 'react';
import useAuth from 'kikifarmer/hooks/useAuth';
import { getStringFromLocalStorage, setSession } from '@kikisoftware/utilities';
import { env } from 'kikifarmer/utils/configs';
import http from 'kikifarmer/api/api-services/http';
import { ErrorUtils } from 'kikifarmer/api/api-services/error';
import appRoutes from 'kikifarmer/routes/path';

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const { getUserProfile } = useAuth();
  // const [loading, setLoading] = useState(true);

  // useWebConfig();
  // useExternalTranslations();
  // useCheckUserIp();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = getStringFromLocalStorage('authenticated');

        if (accessToken) {
          setSession(accessToken, env);
          http.setAuthorization(accessToken);

          await getUserProfile();
        } else {
          // setSystemConfigs({
          //   statistic: undefined,
          //   isAuthenticated: false,
          //   isInitialize: true,
          // });
        }
      } catch (err) {
        ErrorUtils.handleError(err, {
          isUnauthorizedErrorFn: (error) => {
            console.log('errr', error);
            window.location.href = `${appRoutes.login.fullPath}&lang=en&path=${location.pathname}`;
          },
        });
      }
    };

    initialize();
  }, []);

  return <>{children}</>;
};

export default AuthProvider;
