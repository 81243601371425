import React, { useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Collapse, LinkProps, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { SidebarItem, SidebarItems } from 'kikifarmer/components/sidebar/SidebarConfig';
import NavItemCollapse from 'kikifarmer/components/sidebar/NavItemCollapse';

export const ListItemStyle = styled(ListItemButton)<
  Omit<LinkProps, 'to'> & { component?: JSX.Element | string | React.ReactNode; to?: string }
>(({ theme }) => ({
  ...theme.typography.body2,
  height: 36,
  position: 'relative',
  textTransform: 'capitalize',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 5,
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
  marginTop: theme.spacing(0.75),
  marginBottom: theme.spacing(1.25),
  color: theme.palette.text.primary,
  fontSize: '1rem',
  fontWeight: 600,
  '&:before': {
    top: 0,
    left: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

export const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  minWidth: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'currentcolor',
});

// ----------------------------------------------------------------------
export const activeRootStyle = {
  color: 'primary.main',
  fontWeight: 'fontWeightMedium',
  bgcolor: 'background.primary',
  '&:before': { display: 'block' },
};

export const activeSubStyle = {
  color: 'text.primary',
  fontWeight: 'fontWeightMedium',
};

function NavItem({ item, isShow, onExpand }: { item: SidebarItem; isShow: boolean; onExpand: () => void }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { title, path, icon, info, children } = item;
  const isActiveRoot = path ? pathname.indexOf(path) !== -1 : false;

  // console.log('math', { path, pathname, isActiveRoot })

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  const onItemClick = () => {
    if (
      pathname.indexOf('/tools/script/') !== -1 &&
      (pathname.indexOf('edit') !== -1 || path.indexOf('view-script') !== -1)
    ) {
      if (!isShow) {
        onExpand();
      }
    }
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          {isShow && (
            <>
              <ListItemText disableTypography primary={t(title)} sx={{ fontSize: 14 }} />
              {info && info}
              {/*<Box*/}
              {/*  component={Icon}*/}
              {/*  icon={open ? arrowIosDownwardFill : arrowIosForwardFill}*/}
              {/*  sx={{ width: 18, height: 18, mr: 1 }}*/}
              {/*/>*/}
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {children.map((item: SidebarItem) => {
                const { title, path } = item;
                const isActiveSub = path ? pathname.indexOf(path) !== -1 : false;

                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink as any}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                      paddingX: 0,
                      my: 0,
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component='span'
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: (theme) => theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: 'primary.main',
                          }),
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText
                      disableTypography
                      primary={t(title)}
                      sx={{ fontSize: 14, color: isActiveSub ? 'primary.main' : 'inherit' }}
                    />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink as any}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
        marginBottom: isShow ? 0 : 0.5,
        height: isShow ? 36 : 50,
      }}
      onClick={onItemClick}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={t(title)} sx={{ fontSize: 14 }} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

export default function NavSection({
  navConfig,
  isShow = true,
  onExpand,
  ...other
}: {
  navConfig: SidebarItems[];
  isShow: boolean;
  onExpand: () => void;
  [X: string]: any;
}) {
  const { t } = useTranslation();

  return (
    <Box {...other}>
      {navConfig.map((list, idx) => {
        const { subheader, items } = list;
        return (
          <List key={idx} disablePadding>
            {isShow && subheader ? (
              <Typography component='p' variant='caption' color='text.subColor3' textTransform='uppercase' my={2}>
                {t(subheader)}
              </Typography>
            ) : (
              idx > 0 && <Divider />
            )}
            {items.map((item) => {
              if (item.onlyDev) return null;
              return item.isHidden ? null : !item.hideWithMember ? (
                isShow ? (
                  <NavItem key={item.title} item={item} isShow={isShow} onExpand={onExpand} />
                ) : (
                  <NavItemCollapse key={item.title} item={item} isShow={isShow} onExpand={onExpand} />
                )
              ) : null;
            })}
          </List>
        );
      })}
    </Box>
  );
}
