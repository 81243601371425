import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { forwardRef, PropsWithChildren, useCallback, useEffect } from 'react';
// material
import { Box } from '@mui/material';
// utils
import { useTranslation } from 'react-i18next';
import { HeaderTitleProps } from 'kikifarmer/layouts/interfaces/layout-provider.interface';
import { useLayoutProvider } from 'kikifarmer/layouts/LayoutProvider';
import track from 'kikifarmer/utils/analytics';
import { useCollapseDrawer } from 'kikifarmer/layouts/CollapseDrawerProvider';

// ----------------------------------------------------------------------

interface PageProps {
  title?: string;
  headerTitles?: HeaderTitleProps[];
  hideHeader?: string;
  collapseSidebar?: boolean;
  [X: string]: any;
}

const PageWrapper = forwardRef<PropsWithChildren<PageProps>, PropsWithChildren<PageProps>>(
  ({ children, title = '', headerTitles, hideHeader = false, collapseSidebar, ...other }, ref) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { onHideHeader, onShowHeader, onSetHeaderTitles } = useLayoutProvider();
    const { onCollapse } = useCollapseDrawer();

    const sendPageViewEvent = useCallback(() => {
      track.pageview({
        page_path: pathname,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);

    useEffect(() => {
      if (headerTitles) {
        onSetHeaderTitles(headerTitles);
      }
    }, [headerTitles]);

    useEffect(() => {
      if (hideHeader) {
        onHideHeader();
      } else {
        onShowHeader();
      }
    }, [hideHeader]);

    useEffect(() => {
      if (collapseSidebar) {
        onCollapse();
      }
    }, [collapseSidebar]);

    return (
      <Box ref={ref} {...other}>
        <Helmet>
          <title>{t(title)} | KikiFarmer</title>
        </Helmet>
        {children}
      </Box>
    );
  },
);

export default PageWrapper;
